import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaEdit } from "react-icons/fa"
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs"
import { HiOutlineDocumentReport, HiRefresh,  } from "react-icons/hi"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { GrDrag } from "react-icons/gr";

import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Carousel } from 'react-responsive-carousel'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import BtnOrange from 'components/Button/BtnOrange'
import LoadingScreen from 'components/LoadingScreen'

import Td from 'components/Td'

import api from 'services/api/api'
import { handleNewToken, handlePowerBiReportAad } from 'services/api/powerbi'

import { CarouselContainer, ItemContainer } from './styled'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Context } from 'contexts/context'
import { InputCadastrar } from 'pages/Cards/styled'
import InputWithSearchIcon from 'components/InputSearch'

export default function Carrousel() {
    const [carousels, setCarousels] = useState([])
    const [data, setData] = useState([])    
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(true)
    const [showCarousel, setShowCarousel] = useState(false)
    const [intervalz, setIntervalz] = useState(400)
    const { user } = useContext(Context)
    const [powerBiComponentRef, setPowerBiComponentRef] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const handle = useFullScreenHandle()
    const [isTop, setIsTop] = useState(false)

    const handlePlay = async (id) => {
        setIsLoading(true)

        try {
            const { data } = await api.get(`/carousels/${id}/reports/powerbi`)

            setData(data.map(item => ({
                reportId: item.report.report_id,
                embedUrl: item.powerbi.embedUrl,
                token: item.powerbi.accessToken,
                type: models.TokenType.Embed,
                time: item.time,
                report_section: item.report_section,
                roles: item.roles,
                dataset_id: item.report.dataset_id,
                group_id: item.report.group_id
            })))
            // .flatMap((item, index) => index === 0 ? [item, item] : [item]))

            // setIntervalz(data[0].time)
            setIsLoaded(true)

            await handle.enter()
        } catch(error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (user.roles === 'admin') {
                    const { data } = await api.get(`/carousels/by-firm`)
                    setCarousels(data)
                } else {
                    const { data } = await api.get(`/carousels/by-user`)
                    setCarousels(data)
                }
                
            } catch(error) {

            }
          })()
    }, [])

    const getData = async (report_id) => {
        try {
            const reportData = await handlePowerBiReportAad({ report_id })
            return reportData.accessToken
        } catch(error) {}
    }

    const onChangeFullScreen = (isFullScreen) => {
        setShowCarousel(isFullScreen)
        
        if  (!isFullScreen) {
            setIsLoaded(false)
        }
    }

    const carouselRef = useRef(null);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                carouselRef.current.increment();
            } else if (event.key === 'ArrowLeft') {
                carouselRef.current.decrement();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const onChangeCarousel = async (index, item) => {
        if (index === 0) { 
            setIntervalz(400)
        } else {
            const refreshInterval = item.props["data-interval"]
            setIntervalz(refreshInterval)
        }
        setCurrentIndex(index)
    }

    useEffect(() => {
        if (currentIndex === 0) {
            setIntervalz(400)
        }
    }, [currentIndex]); // Adiciona um efeito para monitorar mudanças em intervalz

    const refreshReport = async (index, ref) => {
        if (ref[index] && ref[index].current) {
            let powerBi = ref[index].current._embed;

            if (powerBi) {
                try {
                    await powerBi.refresh()
                    const newToken = await handleNewToken(data[index])
                    await powerBi.setAccessToken(newToken)
                } catch (error) {
                    console.log("Erro durante o refresh:", error);
                }
            }
        }
    };
    

    useEffect(() => {
        if (data.length > 0) {
            const refs = data.map(() => React.createRef());
            setPowerBiComponentRef(refs);            
        }
    }, [data]);

    useEffect(() => {
        const updateAllReports = async () => {
                for (let index = 1; index < powerBiComponentRef.length; index++) {
                    await refreshReport(index, powerBiComponentRef);
                }
                await refreshReport(0, powerBiComponentRef);
        };
        
        const interval = setInterval(updateAllReports, (60000 * 10)); // Atualiza a cada 10 min
        return () => clearInterval(interval);
    
    }, [data, powerBiComponentRef]);

    const [filter, setFilter] = useState('');

    const filteredData = carousels.filter(item => item.name.toLowerCase()
    .includes(filter.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))
    
    const handleDecrement = () => {
        if (carouselRef.current) {
            requestAnimationFrame(() => {
                carouselRef.current.decrement();
            });
        }
    };

    const handleIncrement = () => {
        if (carouselRef.current) {
            requestAnimationFrame(() => {
                carouselRef.current.increment();
            });
        }
    };

    const togglePlayPause = () => {
        setIsLoaded(prev => {
            const newState = !prev;
            return newState;
        });
    };

    const moveMenu = () => {
        setIsTop(prev => !prev)
    }

    return (
        <>
        <TitleBar label="Painel TV" currentPage="" />

        <Content>
            <Card>
                <CardTitle title="Listagem">
                <InputCadastrar>
                    <InputWithSearchIcon 
                        type="text" value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtrar por nome..." />
                    {user.roles === 'admin' ? (
                                        <>
                        <LinkBlue to="/carrosseis/cadastrar">Cadastrar</LinkBlue>
                        </>
                    ) : <div/>}
                </InputCadastrar>
                </CardTitle>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th>Nome</th>
                            <th width="100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map(carousel =>
                            <tr key={carousel.id}>
                                <td>#{carousel.id}</td>
                                <td>{carousel.name}</td>

                                <Td>
                                    <BtnOrange style={{ padding: '5px 20px' }} onClick={() => handlePlay(carousel.id)}>
                                        <BsFillPlayFill />
                                    </BtnOrange>
                                    {user.roles === 'admin' && (
                                    <>
                                    <SmLinkBlue to={`/carrosseis/${carousel.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>

                                    <SmLinkBlue to={`/carrosseis/${carousel.id}/relatorios`}>
                                        <HiOutlineDocumentReport />
                                    </SmLinkBlue>
                                    </>
                                    )
                                    }
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>

        <FullScreen onChange={onChangeFullScreen} handle={handle} >
            {showCarousel && (   
                <>
                        {/* <div>  */}
                            {/* Aplica o ref ao container */}
                                <CarouselContainer isTop={isTop}>
                                    <button onClick={moveMenu}>
                                        {isTop ? <MdKeyboardArrowDown size={20}/> : <MdKeyboardArrowUp size={20}/>}
                                    </button>
                                    <button onClick={handleDecrement}>
                                        <MdKeyboardArrowLeft size={20}/> {/* Use BsArrowRight para Próximo */}
                                    </button>
                                    <button onClick={togglePlayPause}>
                                        {isLoaded ? <BsFillPauseFill size={20}/> : <BsFillPlayFill size={20}/>}
                                    </button>
                                    <button onClick={handleIncrement}>
                                        <MdKeyboardArrowRight size={20}/> {/* Use BsArrowLeft para Anterior */}
                                    </button>
                                    <button onClick={() => refreshReport(currentIndex - 1, powerBiComponentRef)}>
                                        <HiRefresh size={20}/>
                                    </button>
                                </CarouselContainer>
                        {/* </div> */}
                <Carousel
                    showThumbs={false}
                    stopOnHover={false}
                    showStatus={false}
                    showArrows={false}
                    infiniteLoop={true}
                    showIndicators={false}
                    useKeyboardArrows={true}
                    autoPlay={isLoaded}
                    interval={intervalz}
                    onChange={onChangeCarousel}
                    ref={carouselRef}
                >
                    {/* Adiciona um item de carregamento no índice 0 */}
                    <ItemContainer key={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <img style={{ width: '150px' }} src="loading.gif" alt="Loading..." /> {/* Substitua pelo caminho do seu gif de loading */}
                        </div>
                    </ItemContainer>
                    {data.map((item, index) => (
                        <ItemContainer data-interval={item.time} key={index + 1}> {/* Ajusta o índice para começar a partir de 1 */}
                            <PowerBIEmbed
                                ref={powerBiComponentRef[index]}
                                eventHandlers = {
                                new Map([
                                    ['loaded', () => { 
                                        // setIsLoaded(true)
                                    }],
                                    ['rendered', () => {
                                        // setIsLoaded(true)
                                    }],
                                    ['error', event => console.log(event.detail)]
                                ])
                                }
                                embedConfig={{
                                    type: 'report',
                                    id: item.reportId,
                                    embedUrl: item.embedUrl + `&pageName=${item.report_section}`,
                                    eventHooks: (item.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData(item.report.report_id) },
                                    accessToken: item.token,
                                    tokenType: models.TokenType.Embed,
                                    settings: {
                                        layoutType: models.LayoutType.Custom,
                                        customLayout: {
                                            displayOption: models.DisplayOption.FitToWidth
                                        },
                                        panes: {
                                            filters: {
                                                visible: item.enable_filters ? item.enable_filters : false
                                            },
                                            pageNavigation: {
                                                visible: item.page_navigation ? item.page_navigation : false
                                            }
                                        }
                                    }
                                }}
                                cssClassName={"personalizedReport"}
                            />
                        </ItemContainer>
                    ))}
                </Carousel>
                </>
            )} 
        </FullScreen>

        <LoadingScreen loading={isLoading} />
        </>
    )
}
