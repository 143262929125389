import styled from 'styled-components'

export const ItemContainer = styled.div`
    iframe {
        margin: 0 !important;
    }

    .personalizedReport {
        height: 100vh;
        width: 100%;
        position: relative;
    }
`

export const CarouselContainer = styled.div`
    // background-color: black;
    position: absolute;
    ${props => props.isTop ? `top: 2vh;` : `bottom: 2vh;`}
    right: 44vw;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(128, 128, 128, 0.7);
        border: none;
        padding: 7px;
        margin: 0 5px;
        cursor: pointer;
        border-radius: 50px;
    }
`


