import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaEdit } from "react-icons/fa"

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'

import Td from 'components/Td'

import api from 'services/api/api'

export default function CarrouselReport() {
    const [carouselReports, setCarouselsItems] = useState([])
    const [carousel, setCarousel] = useState({})

    const params = useParams()
    console.log(params.id) // Verifique o ID

    console.log(carouselReports)
    console.log(carousel)

    useEffect(() => {
        (async () => {
          try {
            const { data } = await api.get(`/carousels/${params.id}`)
            setCarousel(data)
          } catch(error) {
            console.error(error) // Adicionei para capturar erros
          }
        })()
    }, [params.id])

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/carousels/${params.id}/reports`)
                setCarouselsItems(data)
            } catch(error) {
                console.error(error) // Adicionei para capturar erros
            }
          })()
    }, [params.id])

    return (
        <>
        <TitleBar label='Painel TV' currentPage="" />

        <Content>
            <Card>
                <CardTitle title='Carrousel'></CardTitle>

                <Table>
                        <thead>
                        <tr>
                            <th>Nome</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{carousel.name}</td>
                        </tr>
                        </tbody>
                    </Table>
            </Card>

            <Card>
                <CardTitle title="Relatórios">
                    <LinkBlue to={`/carrosseis/${carousel.id}/relatorios/cadastrar`}>Cadastrar</LinkBlue>
                </CardTitle>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th >Nome</th>
                            <th >Report Section</th>
                            <th >Roles</th>
                            <th >Duração</th>
                            <th width="100">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {carouselReports.map(carouselReport =>
                            <tr key={carouselReport.id}>
                                <td style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{carouselReport.id}</td>
                                <td style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{carouselReport.report.name}</td>
                                <td style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{carouselReport.report_section}</td>
                                <td style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{carouselReport.roles}</td>
                                <td style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{carouselReport.time / 1000 >= 60 ? `${carouselReport.time / 1000 / 60} Minutos` : `${carouselReport.time / 1000} Segundos`}</td>

                                <Td>
                                    <SmLinkBlue to={`/carrosseis/${carousel.id}/relatorios/${carouselReport.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>

    </>
    )
}